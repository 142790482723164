/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://covidsim.cs.hm.edu/assets/Material/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://covidsim.cs.hm.edu/assets/Material/MaterialIcons-Regular.woff) format('woff'),
    url(https://covidsim.cs.hm.edu/assets/Material/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('https://covidsim.cs.hm.edu/assets/Roboto/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
